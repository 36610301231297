import React from 'react';
import Hero from '../components/Hero';

const Quote = () => {
  return (
    <>
      <Hero>
        GET <br />
        YOUR
        <br />
        PROJECT <br />
        STARTED
      </Hero>
    </>
  );
};

export default Quote;
