import React from 'react';
import Hero from '../components/Hero';

const Faq = () => {
  return (
    <>
      <Hero
        titleFirstLine={{ text: 'WE', colourClass: 'primary' }}
        titleSecondLine={{ text: 'KNOW', colourClass: 'white' }}
        titleThirdLine={{ text: 'OUR', colourClass: 'primary' }}
        titleFourthLine={{ text: 'STUFF', colourClass: 'white' }}
        subTitleText="Turn Your Most Extravagant Ideas Into Something Remarkable!"
        paragraphText="We believe in your ideas with as much passion and enthusiasm as you do. We will take your ideas and forge them into a workable solution that doesn’t just function smoothly, but looks amazing too. Our unrivalled work ethic and collaborative approach means your
        project will reflect your business in a truly authentic style."
        image="services-img"
      />
    </>
  );
};

export default Faq;
