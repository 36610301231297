import React from 'react';
import Hero from '../../components/Hero';

const WebDevelopment = (props) => {
  return (
    <>
      <Hero
        titleFirstLine={{ text: 'WEB', colourClass: 'primary' }}
        titleSecondLine={{ text: 'DEVELOPMENT', colourClass: 'white' }}
        titleThirdLine={{ text: '', colourClass: 'primary' }}
        titleFourthLine={{ text: '', colourClass: 'white' }}
        subTitleText="UK Based Web Development Services"
        paragraphText="We believe in your ideas with as much passion and enthusiasm as you do. We will take your ideas and forge them into a workable solution that doesn’t just function smoothly, but looks amazing too. Our unrivalled work ethic and collaborative approach means your
    project will reflect your business in a truly authentic style."
        image="services-web"
      />
    </>
  );
};

export default WebDevelopment;
