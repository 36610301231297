import { v4 } from "uuid";

const AuthReducer = (state, action) => {
  const token = `TK-${v4()}`;
  switch (action.type) {
    case "LOGIN":
      localStorage.setItem("userToken", JSON.stringify(token));

      return {
        ...state,
        isAuthenticated: !!action.payload,
      };
    case "LOGOUT":
      localStorage.clear();
      return {
        ...state,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};

export default AuthReducer;
