import React from 'react';
import { Link } from 'react-router-dom';
import Particles from 'react-particles-js';
import Typed from 'react-typed';

const Hero = ({ titleFirstLine, titleSecondLine, titleThirdLine, titleFourthLine, subTitleText, paragraphText, image, children }) => {
  return (
    <>
      <div id="carouselExampleSlidesOnly" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner">
          <div className="carousel-item active" style={{ height: '800px', backgroundColor: `#000000`, backgroundSize: 'cover' }}>
            <div className="container pt-10 px-0">
              <div className="row text-center justify-content-center position-absolute" style={{ zIndex: '9000', left: ' 0', right: '0', margin: 'auto' }}>
                <div className="col-12 col-lg-8">
                  <div className="row align-items-center justify-content-center">
                    <div className="col-12">
                      <div className="row justify-content-center">
                        <div className="col-12">
                          <div className="border-bottom border-1 mb-3" />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <h1 id="hero-text" className="display-4">
                            <span className={`text-${titleFirstLine.colourClass}`}>
                              <Typed typeSpeed={200} startDelay={800} backSpeed={100} strings={[titleFirstLine.text]} smartBackspace shuffle={false} backDelay={5000} fadeOut={false} fadeOutDelay={100} loopCount={0} showCursor cursorChar="" />
                            </span>
                            <span className={`text-${titleSecondLine.colourClass}`}> {titleSecondLine.text} </span>
                            <span className={`text-${titleThirdLine.colourClass}`}>
                              <Typed typeSpeed={200} backSpeed={100} strings={[titleThirdLine.text]} smartBackspace shuffle={false} backDelay={5000} fadeOut={false} fadeOutDelay={100} loopCount={0} showCursor cursorChar="" />
                            </span>
                            <br />
                            <span className={`text-${titleFourthLine.colourClass}`}>{titleFourthLine.text}</span>
                          </h1>
                        </div>
                      </div>

                      <div className="row justify-content-center">
                        <div className="col-12">
                          <div className="border-bottom border-1 mt-3 mb-2" />
                        </div>
                      </div>

                      <div className="row justify-content-center">
                        <div className="col-8">
                          <h2 className="h2 text-white mb-3 fw-bold">{subTitleText}</h2>
                          <p className="p text-white">{paragraphText}</p>
                        </div>
                        <div className="col-12 mt-4" style={{ pointerEvents: 'auto' }}>
                          <Link to="./quote" className="btn btn-primary">
                            Discuss Your Idea
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-5">
                  <img src={require(`../assets/images/${image}.png`).default} alt="something here" width="500px" className="img-fluid" />
                </div> */}
              </div>

              <Particles
                height="800px"
                params={{
                  autoPlay: true,
                  background: {
                    color: {
                      value: '#000000',
                    },
                    image: '',
                    position: '',
                    repeat: '',
                    size: '',
                    opacity: 1,
                  },
                  backgroundMask: {
                    composite: 'destination-out',
                    cover: {
                      color: {
                        value: '#fff',
                      },
                      opacity: 1,
                    },
                    enable: false,
                  },
                  defaultThemes: {},
                  delay: 0,
                  fullScreen: {
                    enable: false,
                    zIndex: 1,
                  },
                  detectRetina: true,
                  duration: 0,
                  fpsLimit: 60,
                  interactivity: {
                    detectsOn: 'window',
                    events: {
                      onClick: {
                        enable: false,
                        mode: [],
                      },
                      onDiv: {
                        selectors: [],
                        enable: false,
                        mode: [],
                        type: 'circle',
                      },
                      onHover: {
                        enable: true,
                        mode: 'bubble',
                        parallax: {
                          enable: false,
                          force: 2,
                          smooth: 10,
                        },
                      },
                      resize: {
                        delay: 0.5,
                        enable: true,
                      },
                    },
                    modes: {
                      attract: {
                        distance: 200,
                        duration: 0.4,
                        easing: 'ease-out-quad',
                        factor: 1,
                        maxSpeed: 50,
                        speed: 1,
                      },
                      bounce: {
                        distance: 200,
                      },
                      bubble: {
                        distance: 400,
                        duration: 2,
                        mix: false,
                        opacity: 0.8,
                        color: {
                          value: '#ff0000',
                        },
                        size: 40,
                        divs: {
                          distance: 200,
                          duration: 0.4,
                          mix: false,
                          selectors: [],
                        },
                      },
                      connect: {
                        distance: 80,
                        links: {
                          opacity: 0.5,
                        },
                        radius: 60,
                      },
                      grab: {
                        distance: 100,
                        links: {
                          blink: false,
                          consent: false,
                          opacity: 1,
                        },
                      },
                      push: {
                        default: true,
                        groups: [],
                        quantity: 4,
                      },
                      remove: {
                        quantity: 2,
                      },
                      repulse: {
                        distance: 200,
                        duration: 0.4,
                        factor: 100,
                        speed: 1,
                        maxSpeed: 50,
                        easing: 'ease-out-quad',
                        divs: {
                          distance: 200,
                          duration: 0.4,
                          factor: 100,
                          speed: 1,
                          maxSpeed: 50,
                          easing: 'ease-out-quad',
                          selectors: [],
                        },
                      },
                      slow: {
                        factor: 3,
                        radius: 200,
                      },
                      trail: {
                        delay: 1,
                        pauseOnStop: false,
                        quantity: 1,
                      },
                      light: {
                        area: {
                          gradient: {
                            start: {
                              value: '#ffffff',
                            },
                            stop: {
                              value: '#000000',
                            },
                          },
                          radius: 1000,
                        },
                        shadow: {
                          color: {
                            value: '#000000',
                          },
                          length: 2000,
                        },
                      },
                    },
                  },
                  manualParticles: [],
                  particles: {
                    bounce: {
                      horizontal: {
                        random: {
                          enable: false,
                          minimumValue: 0.1,
                        },
                        value: 1,
                      },
                      vertical: {
                        random: {
                          enable: false,
                          minimumValue: 0.1,
                        },
                        value: 1,
                      },
                    },
                    collisions: {
                      absorb: {
                        speed: 2,
                      },
                      bounce: {
                        horizontal: {
                          random: {
                            enable: false,
                            minimumValue: 0.1,
                          },
                          value: 1,
                        },
                        vertical: {
                          random: {
                            enable: false,
                            minimumValue: 0.1,
                          },
                          value: 1,
                        },
                      },
                      enable: false,
                      mode: 'bounce',
                      overlap: {
                        enable: true,
                        retries: 0,
                      },
                    },
                    color: {
                      value: '#1b1e34',
                      animation: {
                        h: {
                          count: 0,
                          enable: false,
                          offset: 0,
                          speed: 1,
                          decay: 0,
                          sync: true,
                        },
                        s: {
                          count: 0,
                          enable: false,
                          offset: 0,
                          speed: 1,
                          decay: 0,
                          sync: true,
                        },
                        l: {
                          count: 0,
                          enable: false,
                          offset: 0,
                          speed: 1,
                          decay: 0,
                          sync: true,
                        },
                      },
                    },
                    groups: {},
                    move: {
                      angle: {
                        offset: 0,
                        value: 90,
                      },
                      attract: {
                        distance: 200,
                        enable: false,
                        rotate: {
                          x: 3000,
                          y: 3000,
                        },
                      },
                      center: {
                        x: 50,
                        y: 50,
                        mode: 'percent',
                        radius: 0,
                      },
                      decay: 0,
                      distance: {},
                      direction: 'none',
                      drift: 0,
                      enable: true,
                      gravity: {
                        acceleration: 9.81,
                        enable: false,
                        inverse: false,
                        maxSpeed: 50,
                      },
                      path: {
                        clamp: true,
                        delay: {
                          random: {
                            enable: false,
                            minimumValue: 0,
                          },
                          value: 0,
                        },
                        enable: false,
                        options: {},
                      },
                      outModes: {
                        default: 'out',
                        bottom: 'out',
                        left: 'out',
                        right: 'out',
                        top: 'out',
                      },
                      random: true,
                      size: true,
                      speed: 0.5,
                      spin: {
                        acceleration: 20,
                        enable: true,
                      },
                      straight: false,
                      trail: {
                        enable: false,
                        length: 10,
                        fill: {},
                      },
                      vibrate: false,
                      warp: true,
                    },
                    number: {
                      density: {
                        enable: true,
                        width: 1920,
                        height: 1080,
                      },
                      limit: 0,
                      value: 6,
                    },
                    opacity: {
                      random: {
                        enable: false,
                        minimumValue: 0.1,
                      },
                      value: {
                        min: 0.3,
                        max: 0.5,
                      },
                      animation: {
                        count: 0,
                        enable: false,
                        speed: 2,
                        decay: 0,
                        sync: false,
                        destroy: 'none',
                        startValue: 'random',
                      },
                    },
                    reduceDuplicates: false,
                    shadow: {
                      blur: 0,
                      color: {
                        value: '#000',
                      },
                      enable: false,
                      offset: {
                        x: 0,
                        y: 0,
                      },
                    },
                    shape: {
                      options: {
                        polygon: {
                          sides: 4,
                        },
                        star: {
                          sides: 6,
                        },
                      },
                      type: 'polygon',
                    },
                    size: {
                      random: {
                        enable: false,
                        minimumValue: 1,
                      },
                      value: {
                        min: 20,
                        max: 50,
                      },
                      animation: {
                        count: 0,
                        enable: false,
                        speed: 5,
                        decay: 0,
                        sync: false,
                        destroy: 'none',
                        startValue: 'random',
                      },
                    },
                    stroke: {
                      width: 0,
                    },
                    zIndex: {
                      random: {
                        enable: false,
                        minimumValue: 0,
                      },
                      value: 0,
                      opacityRate: 1,
                      sizeRate: 1,
                      velocityRate: 1,
                    },
                    life: {
                      count: 0,
                      delay: {
                        random: {
                          enable: false,
                          minimumValue: 0,
                        },
                        value: 0,
                        sync: false,
                      },
                      duration: {
                        random: {
                          enable: false,
                          minimumValue: 0.0001,
                        },
                        value: 0,
                        sync: false,
                      },
                    },
                    rotate: {
                      random: {
                        enable: false,
                        minimumValue: 0,
                      },
                      value: 0,
                      animation: {
                        enable: false,
                        speed: 0,
                        decay: 0,
                        sync: false,
                      },
                      direction: 'clockwise',
                      path: false,
                    },
                    destroy: {
                      bounds: {},
                      mode: 'none',
                      split: {
                        count: 1,
                        factor: {
                          random: {
                            enable: false,
                            minimumValue: 0,
                          },
                          value: 3,
                        },
                        rate: {
                          random: {
                            enable: false,
                            minimumValue: 0,
                          },
                          value: {
                            min: 4,
                            max: 9,
                          },
                        },
                        sizeOffset: true,
                        particles: {},
                      },
                    },
                    roll: {
                      darken: {
                        enable: false,
                        value: 0,
                      },
                      enable: false,
                      enlighten: {
                        enable: false,
                        value: 0,
                      },
                      mode: 'vertical',
                      speed: 25,
                    },
                    tilt: {
                      random: {
                        enable: false,
                        minimumValue: 0,
                      },
                      value: 0,
                      animation: {
                        enable: false,
                        speed: 0,
                        decay: 0,
                        sync: false,
                      },
                      direction: 'clockwise',
                      enable: false,
                    },
                    twinkle: {
                      lines: {
                        enable: false,
                        frequency: 0.05,
                        opacity: 1,
                      },
                      particles: {
                        enable: false,
                        frequency: 0.05,
                        opacity: 1,
                      },
                    },
                    wobble: {
                      distance: 5,
                      enable: false,
                      speed: {
                        angle: 50,
                        move: 10,
                      },
                    },
                    orbit: {
                      animation: {
                        count: 0,
                        enable: false,
                        speed: 1,
                        decay: 0,
                        sync: false,
                      },
                      enable: false,
                      opacity: 1,
                      rotation: {
                        random: {
                          enable: false,
                          minimumValue: 0,
                        },
                        value: 45,
                      },
                      width: 1,
                    },
                    links: {
                      blink: false,
                      color: {
                        value: '#fff',
                      },
                      consent: false,
                      distance: 100,
                      enable: false,
                      frequency: 1,
                      opacity: 1,
                      shadow: {
                        blur: 5,
                        color: {
                          value: '#000',
                        },
                        enable: false,
                      },
                      triangles: {
                        enable: false,
                        frequency: 1,
                      },
                      width: 1,
                      warp: false,
                    },
                    repulse: {
                      random: {
                        enable: false,
                        minimumValue: 0,
                      },
                      value: 0,
                      enabled: false,
                      distance: 1,
                      duration: 1,
                      factor: 1,
                      speed: 1,
                    },
                  },
                  pauseOnBlur: true,
                  pauseOnOutsideViewport: true,
                  responsive: [],
                  smooth: false,
                  style: {},
                  themes: [],
                  zLayers: 100,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
