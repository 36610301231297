import React from 'react';
import { MdCopyright } from 'react-icons/md';

const Footer = () => {
  return (
    <div>
      <footer className="footer">
        <div className="container-fluid">
          <div className="container">
            <div className="row py-5 justify-content-center">
              <div className="col-2 text-start">
                <h6>Menu:</h6>
                <ul className="nav flex-column mt-4">
                  <li className="nav-item my-2 px-0">
                    <a className="nav-link px-0" aria-current="page" href="#">
                      Home
                    </a>
                  </li>
                  <li className="nav-item my-2 px-0">
                    <a className="nav-link px-0" href="#">
                      About Us
                    </a>
                  </li>
                  <li className="nav-item my-2 px-0">
                    <a className="nav-link px-0" href="#">
                      Services
                    </a>
                  </li>
                  <li className="nav-item my-2 px-0">
                    <a className="nav-link px-0" href="#">
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-2 text-start">
                <h6>Services:</h6>
                <ul className="nav flex-column mt-4">
                  <li className="nav-item my-2 px-0">
                    <a className="nav-link px-0" aria-current="page" href="#">
                      Home
                    </a>
                  </li>
                  <li className="nav-item my-2 px-0">
                    <a className="nav-link px-0" href="#">
                      About Us
                    </a>
                  </li>
                  <li className="nav-item my-2 px-0">
                    <a className="nav-link px-0" href="#">
                      Services
                    </a>
                  </li>
                  <li className="nav-item my-2 px-0">
                    <a className="nav-link px-0" href="#">
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-2 bg-secondary">
                <h6>Offices:</h6>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-12 align-items-baseline">
                <MdCopyright className="align-middle" />
                2021 Codethentic Limited - All Rights Reserved
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
