import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Hero from '../components/Hero';
import { Modal } from 'bootstrap';
import { Splide, SplideSlide } from '@splidejs/react-splide';

const Home = () => {
  useEffect(() => {
    const tabEl = document.querySelectorAll('button[data-bs-toggle="tab"]');
    // console.log(tabEl);

    tabEl.forEach((tab) => {
      tab.addEventListener('shown.bs.tab', function (event) {
        console.log(event.target); // newly activated tab
        console.log(event.relatedTarget); // previous active tab
        tabEl.forEach((tab) => {
          tab.classList.remove('active');
        });
      });
    });
  }, []);

  const events = {
    // onDragged: function(event) {...},
    // onChanged: function(event) {...}
  };

  return (
    <>
      <Hero
        titleFirstLine={{ text: 'FORGE ', colourClass: 'white' }}
        titleSecondLine={{ text: ' YOUR', colourClass: 'white' }}
        titleThirdLine={{ text: ' IDEAS', colourClass: 'white' }}
        titleFourthLine={{ text: 'WITH THE LEADING WEB DEVELOPMENT AGENCY', colourClass: 'white' }}
        paragraphText="Your ideas are our inspiration - we take your concept and turn it into reality. From mobile apps to logo design, we have a solution that will bring your idea to life. "
        image="hero-img-one"
      />

      <section className="highlight-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4 text-white p-0">
              <div class="card h-100 bg-primary rounded-0 border-0 p-5">
                <div class="card-body">
                  <div class="row align-items-center">
                    <div class="col-2">
                      <span class="material-symbols-outlined" style={{ fontSize: '4em' }}>
                        verified_user
                      </span>
                    </div>
                    <div class="col-10">
                      <h5 className="text-uppercase ">We Code For The Future</h5>
                      <p className="mb-0">Our code is built to withstand the continuous evolutions in web development.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 text-white p-0">
              <div class="card h-100 bg-dark rounded-0 border-0 p-5">
                <div class="card-body">
                  <div class="row align-items-center">
                    <div class="col-2">
                      <span class="material-symbols-outlined" style={{ fontSize: '4em' }}>
                        local_police
                      </span>
                    </div>
                    <div class="col-10">
                      <h5 className="text-uppercase">We Use The Best Technology</h5>
                      <p className="mb-0">We understand that technolgoy is progressive so we stay on top of the latest trends.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 text-dark p-0">
              <div class="card h-100 bg-tertiary rounded-0 border-0 p-5">
                <div class="card-body">
                  <div class="row align-items-center">
                    <div class="col-2">
                      <span class="material-symbols-outlined" style={{ fontSize: '4em' }}>
                        shield_lock
                      </span>
                    </div>
                    <div class="col-10">
                      <h5 className="text-uppercase">We Build Secure Applications</h5>
                      <p className="mb-0">Our apps are designed, coded and tested with security at the heart of every project.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="approach-section py-10 ">
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-md-9">
              <div className="row justify-content-center">
                <div className="col-12">
                  <div className="border-bottom border-2 mb-3" />
                </div>
              </div>
              <h5 className="section-title display-4 text-uppercase">
                We Are <br /> Codethentic{' '}
              </h5>
              <div className="row justify-content-center">
                <div className="col-12">
                  <div className="border-bottom border-2 mt-3 mb-5" />
                </div>
              </div>
              <p>
                We are a web development agency with a distinct edge. Working collaborateively with our clients, we put our full energy into every project and treat it as our own. From design to go-live, we align with your goals to deliver an outcome that exceeds every expectation and drives your
                business forward, long-term.
              </p>
              <p>Our website development packages deliver services from UX, UI and logo design to custom app development and SEO. We have the passion, knowledge and skillset needed to bring your project to life.</p>
              <div className="row mt-5">
                <div className="col-md-6">
                  <strong></strong>
                  <span>Delivering 100% Client Satisfaction</span>
                </div>
                <div className="col-md-6">
                  <strong></strong>
                  <span>Providing Services To Clients Worldwide</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="services-section py-10 position-relative bg-secondary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5 text-white">
              <div className="row justify-content-center">
                <div className="col-12">
                  <div className="border-bottom border-1 mb-3" />
                </div>
              </div>
              <h5 className="section-title display-4 text-uppercase mb-2 fw-bold">Our Services</h5>
              <div className="row justify-content-center">
                <div className="col-12">
                  <div className="border-bottom border-1 mt-3 mb-5" />
                </div>
              </div>
              <p>Our services range across a variety of web development, design and technology sectors. We work with companies in every industry to achieve high quality products that bring quantifiable results.</p>
              <p>Get ongoing support and excellent web services with us!</p>
              <Link to="/contact" className="btn btn-primary mt-4 mt-xl-5">
                Discuss Your Idea
              </Link>
            </div>
            <div className="col-md-7">
              <div className="row">
                <div className="col-md-4 my-2">
                  <div className="card border-0 text-center p-4 h-100 rounded-0">
                    <div className="row justify-content-center">
                      <div className="col-8">
                        <img src={require(`../assets/images/step-3.png`).default} alt="something here" className="img-fluid" />
                      </div>
                      <div className="col-12">
                        <h5>Web Design</h5>
                        <p>From bespoke website builds, e-commerce websites and CMS management, we have the service you need. </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 my-2">
                  <div className="card border-0 text-center p-4 h-100 rounded-0">
                    <div className="row justify-content-center">
                      <div className="col-8">
                        <img src={require(`../assets/images/step-3.png`).default} alt="something here" className="img-fluid" />
                      </div>
                      <div className="col-12">
                        <h5>Bespoke Web Development</h5>
                        <p>B2B web design and SaaS application development are one of our specialities.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 my-2">
                  <div className="card border-0 text-center p-4 h-100 rounded-0">
                    <div className="row justify-content-center">
                      <div className="col-8">
                        <img src={require(`../assets/images/step-3.png`).default} alt="something here" className="img-fluid" />
                      </div>
                      <div className="col-12">
                        <h5>Website Development</h5>
                        <p>Create a high-quality web presence through excellent website development services.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 my-2">
                  <div className="card border-0 text-center p-4 h-100 rounded-0">
                    <div className="row justify-content-center">
                      <div className="col-8">
                        <img src={require(`../assets/images/step-3.png`).default} alt="something here" className="img-fluid" />
                      </div>
                      <div className="col-12">
                        <h5>Software Testing</h5>
                        <p>Get all the components of your software tested so that no anomalies are left.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 my-2">
                  <div className="card border-0 text-center p-4 h-100 rounded-0">
                    <div className="row justify-content-center">
                      <div className="col-8">
                        <img src={require(`../assets/images/step-3.png`).default} alt="something here" className="img-fluid" />
                      </div>
                      <div className="col-12">
                        <h5>Digital Marketing</h5>
                        <p>Refine your website with digital marketing ideas so that it excels in the digital world.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 my-2">
                  <div className="card border-0 text-center p-4 h-100 rounded-0">
                    <div className="row justify-content-center">
                      <div className="col-8">
                        <img src={require(`../assets/images/step-3.png`).default} alt="something here" className="img-fluid" />
                      </div>
                      <div className="col-12">
                        <h5>Digital Marketing</h5>
                        <p>Refine your website with digital marketing ideas so that it excels in the digital world.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="steps-section py-10">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <h5 className="section-title display-4 text-uppercase mb-2 fw-bold text-center">How We Work</h5>
              <h2 className="text-center pb-4">Steps To Make Our Clients Happy &amp; Satisfied</h2>
              <p className="text-center fw-bolder">Our process is all about collaboration and partnership - your input is vital to our success. From your initial enquiry to the completed project, we communicate with and involve you throughout.</p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-9">
              <div className="row justify-content-around align-items-center my-4">
                <div className="col-3">
                  <img src={require(`../assets/images/step-1.png`).default} alt="something here" className="img-fluid" />
                </div>
                <div className="col-9">
                  <h5 className="fw-bold">Step One</h5>
                  <h4>We Receive Your Enquiry and Contact You</h4>
                  <p>We receive your enquiry and one of our specialists arranges a meeting to explore your requirements in more detail.</p>
                </div>
              </div>
              <div className="row justify-content-around align-items-center my-4">
                <div className="col-9">
                  <h5 className="fw-bold">Step Two</h5>
                  <h4>We Arrange an Initial Meeting to Discuss Your Project</h4>
                  <p>We schedule a meeting and discuss your project - we’ll talk through your needs, ideas and aspirations then formulate a plan to move forward. We’ll keep it real and only recommend services and solutions that will best serve your business.</p>
                </div>
                <div className="col-3">
                  <img src={require(`../assets/images/step-2.png`).default} alt="something here" className="img-fluid" />
                </div>
              </div>
              <div className="row justify-content-around align-items-center my-4">
                <div className="col-3">
                  <img src={require(`../assets/images/step-3.png`).default} alt="something here" className="img-fluid" />
                </div>
                <div className="col-9">
                  <h5 className="fw-bold">Step Three</h5>
                  <h4>We Begin Planning and Create a Proposal</h4>
                  <p>Our designers and developers collaborate to devise a project proposition, schedule of work and pricing options. We’ll discuss this with you in detail and iron out any fine details before commencing.</p>
                </div>
              </div>
              <div className="row justify-content-around align-items-center my-4">
                <div className="col-9">
                  <h5 className="fw-bold">Step Four</h5>
                  <h4>Work In Progress Begins - Your Project is Underway </h4>
                  <p>Our designers and developers get to work. We work tirelessly to innovate and create the ultimate solution, whilst keeping you updated not just on progress but to seek your feedback as we proceed.</p>
                </div>
                <div className="col-3">
                  <img src={require(`../assets/images/step-4.png`).default} alt="something here" className="img-fluid" />
                </div>
              </div>
              <div className="row justify-content-around align-items-center my-4">
                <div className="col-3">
                  <img src={require(`../assets/images/step-5.png`).default} alt="something here" className="img-fluid" />
                </div>
                <div className="col-9">
                  <h5 className="fw-bold">Step Five</h5>
                  <h4>Testing Your Solution is Essential Before We Deliver</h4>
                  <p>We test, check, adjust and test again! We will only deliver your completed project to you once we are happy our stringent testing measures have been met.</p>
                </div>
              </div>
              <div className="row justify-content-around align-items-center my-4">
                <div className="col-9">
                  <h5 className="fw-bold">Step Six</h5>
                  <h4>Your Final Product is Complete</h4>
                  <p>We deliver your completed project, talk you through any relevant details and functionality then deploy our work. We stay with you to ensure everything runs smoothly.</p>
                </div>
                <div className="col-3">
                  <img src={require(`../assets/images/step-6.png`).default} alt="something here" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="why-section bg-secondary py-10 text-white">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 pr-xl-5">
              <div className="row">
                <div className="col-sm-6 my-3">
                  <div className="why-box">
                    <h3 className="counting" data-count={5000}>
                      5000
                    </h3>
                    <p>Project completed by our team of web designers and developers.</p>
                  </div>
                </div>
                <div className="col-sm-6 my-3">
                  <div className="why-box">
                    <h3 className="counting" data-count={120}>
                      120
                    </h3>
                    <p>Experts in web design, branding and digital marketing solutions.</p>
                  </div>
                </div>
                <div className="col-sm-6 my-3">
                  <div className="why-box">
                    <h3 className="counting" data-count={2000}>
                      2000
                    </h3>
                    <p>Customers who were satisfied with our work.</p>
                  </div>
                </div>
                <div className="col-sm-6 my-3">
                  <div className="why-box">
                    <h3 className="counting" data-count={99}>
                      99
                    </h3>
                    <p>Positive reviews sent by our first time and regular customers.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 mt-4 mt-md-0">
              <article>
                <h5 className="section-title display-4 text-center mb-2 fw-bold">Why Choose Us</h5>
                <h2 className="text-center pb-4">We Believe In Digital Expansion Of Every Business Through Unique Approach</h2>
                <p>
                  <b>Codethentic</b> is a technology-driven company that completely understands the changing consumer behavior. In a nutshell, we help our clients with their challenges by getting the most out of our web services.{' '}
                </p>
                <p>
                  <b>
                    Designing websites, <a href="https://www.codethentic.com/mobile-app-development/">mobile applications</a>, and games
                  </b>
                  can be a tedious task, but we enjoy doing it. We take care of the product performance and its seamless functionality, providing multi-touch supports and the latest APIs. Let us be your digital business partner!
                </p>
                <Link className="btn btn-outline-secondary wow animate__ animate__shakeX animated" data-wow-delay="2s" to="/quote" style={{ visibility: 'visible', animationDelay: '2s', animationName: 'shakeX' }}>
                  Get a Quote
                </Link>
              </article>
            </div>
          </div>
        </div>
      </section>

      <section className="technologies-sec py-10">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <h2 className="section-title display-4 text-center mb-2 fw-bold">Technology Expertise</h2>
              <p className="text-center pb-4">We are the no. 1 IT company based in United Kingdom working with a sole purpose of providing a customer satisfactory work</p>
            </div>
            <div className="col-12 col-md-9 border-bottom border-3">
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <Splide
                  className="w-100"
                  options={{
                    rewind: true,
                    gap: '1rem',
                    perPage: 4,
                    breakpoints: {
                      640: {
                        perPage: 2,
                      },
                    },
                  }}
                >
                  <SplideSlide>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">
                        <img src={require(`../assets/images/step-2.png`).default} alt="something here" className="img-fluid" />

                        <h6 className="text-dark">
                          <strong>Operating System</strong>
                        </h6>
                      </button>
                    </li>
                  </SplideSlide>
                  <SplideSlide>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">
                        <img src={require(`../assets/images/step-2.png`).default} alt="something here" className="img-fluid" />
                        <h6 className="text-dark">
                          <strong>Technology</strong>
                        </h6>
                      </button>
                    </li>
                  </SplideSlide>
                  <SplideSlide>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="messages-tab" data-bs-toggle="tab" data-bs-target="#messages" type="button" role="tab" aria-controls="messages" aria-selected="false">
                        <img src={require(`../assets/images/step-2.png`).default} alt="something here" className="img-fluid" />
                        <h6 className="text-dark">
                          <strong>Web Services</strong>
                        </h6>
                      </button>
                    </li>
                  </SplideSlide>
                  <SplideSlide>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="settings-tab" data-bs-toggle="tab" data-bs-target="#settings" type="button" role="tab" aria-controls="settings" aria-selected="false">
                        <img src={require(`../assets/images/step-2.png`).default} alt="something here" className="img-fluid" />
                        <h6 className="text-dark">
                          <strong>Database</strong>
                        </h6>
                      </button>
                    </li>
                  </SplideSlide>
                  <SplideSlide>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="another-tab" data-bs-toggle="tab" data-bs-target="#another" type="button" role="tab" aria-controls="settings" aria-selected="false">
                        <img src={require(`../assets/images/step-2.png`).default} alt="something here" className="img-fluid" />
                        <h6 className="text-dark">
                          <strong>Database</strong>
                        </h6>
                      </button>
                    </li>
                  </SplideSlide>
                </Splide>
              </ul>
            </div>
          </div>
          <div className="row justify-content-center mt-5">
            <div class="col-12 col-md-9">
              <div className="tab-content">
                <div className="tab-pane active" id="home" role="tabpanel" aria-labelledby="home-tab">
                  <div class="row justify-content-center">
                    <div class="col-6 col-md-4 col-md-2 tech-tile position-relative text-center">
                      <img alt="something here" class="img-fluid" src="https://www.ficode.co.uk/wp-content/uploads/2019/02/2-4.png" />
                    </div>
                    <div class="col-6 col-md-4 col-md-2 tech-tile position-relative text-center">
                      <img alt="something here" class="img-fluid" src="https://www.ficode.co.uk/wp-content/uploads/2019/02/2-4.png" />
                    </div>
                    <div class="col-6 col-md-4 col-md-2 tech-tile position-relative text-center">
                      <img alt="something here" class="img-fluid" src="https://www.ficode.co.uk/wp-content/uploads/2019/02/2-4.png" />
                    </div>
                    <div class="col-6 col-md-4 col-md-2 tech-tile position-relative text-center">
                      <img alt="something here" class="img-fluid" src="https://www.ficode.co.uk/wp-content/uploads/2019/02/2-4.png" />
                    </div>
                    <div class="col-6 col-md-4 col-md-2 tech-tile position-relative text-center">
                      <img alt="something here" class="img-fluid" src="https://www.ficode.co.uk/wp-content/uploads/2019/02/2-4.png" />
                    </div>
                  </div>
                </div>
                <div className="tab-pane" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                  Two
                </div>
                <div className="tab-pane" id="messages" role="tabpanel" aria-labelledby="messages-tab">
                  Three
                </div>
                <div className="tab-pane" id="settings" role="tabpanel" aria-labelledby="settings-tab">
                  Four
                </div>
                <div className="tab-pane" id="another" role="tabpanel" aria-labelledby="settings-tab">
                  Five
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
