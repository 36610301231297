import React, { useReducer, useContext } from 'react';
import combineReducers from 'react-combine-reducers';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { Context } from './context/context';
import { AuthContext } from './context/auth-context';
import { DataContext } from './context/data-context';
import { LayoutContext } from './context/layout-context';

//Auth Reducer
import AuthReducer from './reducers/authReducer';
//User Reducer
import DataReducer from './reducers/dataReducer';
//Layout Reducer
import LayoutReducer from './reducers/layoutReducer';

import Home from './layouts/Home';
import About from './layouts/About';
import Faq from './layouts/Faq';
import Layout from './layouts/Layout';
import Services from './layouts/Services';
import Quote from './layouts/Quote';
import Contact from './layouts/Contact';
import MobileAppDevelopment from './layouts/services/MobileAppDevelopment';
import WebDevelopment from './layouts/services/WebDevelopment';
import BespokeSoftwareDevelopment from './layouts/services/BespokeSoftwareDevelopment';
import SearchEngineOptimization from './layouts/services/SearchEngineOptimization';
import EcommerceDevelopment from './layouts/services/EcommerceDevelopment';
import DigitalMarketing from './layouts/services/DigitalMarketing';

const App = () => {
  const AuthCtx = useContext(AuthContext);
  const DataCtx = useContext(DataContext);
  const LayoutCtx = useContext(LayoutContext);

  const [rootReducerCombined, rootContextCombined] = combineReducers({
    auth: [AuthReducer, AuthCtx],
    data: [DataReducer, DataCtx],
    layout: [LayoutReducer, LayoutCtx],
  });
  const [state, dispatch] = useReducer(rootReducerCombined, rootContextCombined);

  let routes = (
    <>
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/services">
        <Services />
      </Route>
      <Route exact path="/quote">
        <Quote />
      </Route>
      <Route exact path="/services/mobile-app-development">
        <MobileAppDevelopment />
      </Route>
      <Route exact path="/services/web-development">
        <WebDevelopment />
      </Route>
      <Route exact path="/services/bespoke-software-development">
        <BespokeSoftwareDevelopment />
      </Route>
      <Route exact path="/services/search-engine-optimization">
        <SearchEngineOptimization />
      </Route>
      <Route exact path="/services/ecommerce-development">
        <EcommerceDevelopment />
      </Route>
      <Route exact path="/services/ecommerce-development">
        <EcommerceDevelopment />
      </Route>
      <Route exact path="/services/digital-marketing">
        <DigitalMarketing />
      </Route>
      <Route exact path="/faq">
        <Faq />
      </Route>
      <Route exact path="/about-us">
        <About />
      </Route>
      <Route exact path="/contact-us">
        <Contact />
      </Route>
    </>
  );

  return (
    <Router>
      <Context.Provider
        value={{
          state,
          dispatch,
        }}
      >
        <Layout>
          <Routes>{routes}</Routes>
        </Layout>
      </Context.Provider>
    </Router>
  );
};
export default App;
