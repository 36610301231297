import React from 'react';
import Hero from '../../components/Hero';

const EcommerceDevelopment = (props) => {
  return (
    <>
      <Hero
        titleFirstLine={{ text: 'ECOMMERCE', colourClass: 'primary' }}
        titleSecondLine={{ text: 'DEVELOPMENT', colourClass: 'white' }}
        titleThirdLine={{ text: '', colourClass: 'primary' }}
        titleFourthLine={{ text: '', colourClass: 'white' }}
        subTitleText="Turn Your Most Extravagant Ideas Into Something Remarkable!"
        paragraphText="We believe in your ideas with as much passion and enthusiasm as you do. We will take your ideas and forge them into a workable solution that doesn’t just function smoothly, but looks amazing too. Our unrivalled work ethic and collaborative approach means your
    project will reflect your business in a truly authentic style."
        image="services-ecommerce"
      />
    </>
  );
};

export default EcommerceDevelopment;
