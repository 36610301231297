const layoutReducer = (state, action) => {
  switch (action.type) {
    // MENU TOGGLE

    case "UPDATE_ALL_LAYOUTS":
      return { ...state, allLayouts: action.data };

    case "SET_LAYOUT_VISITED":
      const tempArray = [...state.allLayouts];
      const index = tempArray.findIndex((item) => {
        return item.slug === action.slug;
      });
      tempArray[index].completed = true;

      return {
        ...state,
        allLayouts: tempArray,
      };

    case "LOGOUT":
      return {
        ...state,
        allLayouts: [
          {
            title: "Welcome",
            slug: "welcome",
            completed: false,
          },
          { title: "Selection", slug: "selection", completed: false },
          { title: "Finance", slug: "finance", completed: false },
          { title: "Part Exchange", slug: "part-exchange", completed: false },
          { title: "Details", slug: "details", completed: false },
          { title: "Summary", slug: "summary", completed: false },
        ],
      };

    //DEFAULT
    default:
      return state;
  }
};

export default layoutReducer;
