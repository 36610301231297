import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { MdMenu } from 'react-icons/md';

import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import logo from '../../src/assets/images/codethentic-logo.png';

const Menu = () => {
  let navigate = useNavigate();
  const location = useLocation();

  const [currentLocation, setCurrentLocation] = useState(location.pathname.replace(/^\/|\/$/g, '').toLowerCase());
  console.log(currentLocation);
  // console.log(location.pathname.replace(/^\/|\/$/g, '').toLowerCase());

  useEffect(() => {
    // Sets the
    setCurrentLocation(location.pathname.replace(/^\/|\/$/g, '').toLowerCase());

    const dropdownToggle = document.querySelector('#nav-dropdown .dropdown-toggle');
    const dropdownMenu = document.querySelector('.dropdown-menu');
    dropdownToggle.addEventListener('mouseover', (e) => {
      // dropdownMenu.style.setProperty('display', 'block');
      dropdownMenu.style.display = 'block';
      dropdownToggle.classList.add('active');
    });
    dropdownToggle.addEventListener('mouseout', (e) => {
      // dropdownMenu.style.setProperty('display', 'block');
      dropdownMenu.style.display = 'none';
      if (
        !location.pathname
          .replace(/^\/|\/$/g, '')
          .toLowerCase()
          .includes('services')
      ) {
        dropdownToggle.classList.remove('active');
      } else {
        dropdownToggle.classList.add('active');
      }
    });

    dropdownMenu.addEventListener('mouseover', (e) => {
      // dropdownMenu.style.setProperty('display', 'block');
      dropdownMenu.style.display = 'block';
      dropdownToggle.classList.add('active');
    });
    dropdownMenu.addEventListener('mouseout', (e) => {
      // dropdownMenu.style.setProperty('display', 'block');
      dropdownMenu.style.display = 'none';
      if (
        !location.pathname
          .replace(/^\/|\/$/g, '')
          .toLowerCase()
          .includes('services')
      ) {
        dropdownToggle.classList.remove('active');
      } else {
        dropdownToggle.classList.add('active');
      }
    });
  }, [location]);
  return (
    <header>
      <nav className="navbar navbar-expand-lg bg-transparent pt-4">
        <div className="container-fluid px-5">
          <Link to="/" className="navbar-brand">
            <img src={logo} width="220px" alt="logo" />
          </Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon">
              <MdMenu className="align-middle" />
            </span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div className="navbar-nav ms-auto align-items-center">
              <Link to="./" className={location.pathname.replace(/^\/|\/$/g, '').toLowerCase() == '' ? 'nav-link active' : 'nav-link'}>
                Home
              </Link>
              {/* <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Dropdown
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <a className="dropdown-item" href="#">
                      Action
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Another action
                    </a>
                  </li>
                  <li></li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Something else here
                    </a>
                  </li>
                </ul>
              </li> */}

              <div className="nav-link dropdown" id="nav-dropdown">
                <Link
                  to="./services"
                  type="button"
                  className={
                    location.pathname
                      .replace(/^\/|\/$/g, '')
                      .toLowerCase()
                      .includes('services')
                      ? 'btn px-0 dropdown-toggle active'
                      : 'btn px-0 dropdown-toggle'
                  }
                >
                  Services
                </Link>
                <ul className="dropdown-menu mega-dropdown-menu bg-transparent">
                  <div className="row p-3 px-2 bg-white rounded dropdown-menu-wrapper">
                    <div className="col-12 py-1 ">
                      <Link to="./services/mobile-app-development">
                        <div className={location.pathname.replace(/^\/|\/$/g, '').toLowerCase() == 'services/mobile-app-development' ? 'row align-items-center py-3 px-2 mx-0 active' : 'row align-items-center py-3 px-2 mx-0'}>
                          <div className="col-2 my-0 py-0">
                            <img src={require(`../assets/images/step-3.png`).default} alt="something here" className="img-fluid" />
                          </div>
                          <div className="col-10">Web Design</div>
                        </div>
                      </Link>
                      <hr className="my-0" />
                      <Link to="./services/web-development">
                        <div className={location.pathname.replace(/^\/|\/$/g, '').toLowerCase() == 'services/web-development' ? 'row align-items-center py-3 px-2 mx-0 active' : 'row align-items-center py-3 px-2 mx-0'}>
                          <div className="col-2 my-0 py-0">
                            <img src={require(`../assets/images/step-3.png`).default} alt="something here" className="img-fluid" />
                          </div>
                          <div className="col-10">Bespoke Web Development</div>
                        </div>
                      </Link>
                      <hr className="my-0" />
                      <Link to="./services/bespoke-software-development">
                        <div className={location.pathname.replace(/^\/|\/$/g, '').toLowerCase() == 'services/bespoke-software-development' ? 'row align-items-center py-3 px-2 mx-0 active' : 'row align-items-center py-3 px-2 mx-0'}>
                          <div className="col-2 my-0 py-0">
                            <img src={require(`../assets/images/step-3.png`).default} alt="something here" className="img-fluid" />
                          </div>
                          <div className="col-10">App Development</div>
                        </div>
                      </Link>
                      <hr className="my-0" />
                      <Link to="./services/ecommerce-development">
                        <div className={location.pathname.replace(/^\/|\/$/g, '').toLowerCase() == 'services/ecommerce-development' ? 'row align-items-center py-3 px-2 mx-0 active' : 'row align-items-center py-3 px-2 mx-0'}>
                          <div className="col-2 my-0 py-0">
                            <img src={require(`../assets/images/step-3.png`).default} alt="something here" className="img-fluid" />
                          </div>
                          <div className="col-10">Interface Design</div>
                        </div>
                      </Link>
                      <hr className="my-0" />
                      <Link to="./services/digital-marketing">
                        <div className={location.pathname.replace(/^\/|\/$/g, '').toLowerCase() == 'services/digital-marketing' ? 'row align-items-center py-3 px-2 mx-0 active' : 'row align-items-center py-3 px-2 mx-0'}>
                          <div className="col-2 my-0 py-0">
                            <img src={require(`../assets/images/step-3.png`).default} alt="something here" className="img-fluid" />
                          </div>
                          <div className="col-10">Digital Design & Creative</div>
                        </div>
                      </Link>
                      <hr className="my-0" />
                      <Link to="./services/digital-marketing">
                        <div className={location.pathname.replace(/^\/|\/$/g, '').toLowerCase() == 'services/digital-marketing' ? 'row align-items-center py-3 px-2 mx-0 active' : 'row align-items-center py-3 px-2 mx-0'}>
                          <div className="col-2 my-0 py-0">
                            <img src={require(`../assets/images/step-3.png`).default} alt="something here" className="img-fluid" />
                          </div>
                          <div className="col-10">Support & Maintenance</div>
                        </div>
                      </Link>
                      <hr className="my-0" />
                      <Link to="./services/search-engine-optimization">
                        <div className={location.pathname.replace(/^\/|\/$/g, '').toLowerCase() == 'services/search-engine-optimization' ? 'row align-items-center py-3 px-2 mx-0 active' : 'row align-items-center py-3 px-2 mx-0'}>
                          <div className="col-2 my-0 py-0">
                            <img src={require(`../assets/images/step-3.png`).default} alt="something here" className="img-fluid" />
                          </div>
                          <div className="col-10">Search Engine Optimisation (SEO)</div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </ul>
              </div>

              <Link to="./faq" className={location.pathname.replace(/^\/|\/$/g, '').toLowerCase() == 'faq' ? 'nav-link active' : 'nav-link'}>
                FAQ
              </Link>

              <Link to="./about-us" className={location.pathname.replace(/^\/|\/$/g, '').toLowerCase() == 'about-us' ? 'nav-link active' : 'nav-link'}>
                About Us
              </Link>

              <Link to="./contact-us" className={location.pathname.replace(/^\/|\/$/g, '').toLowerCase() == 'contact-us' ? 'nav-link active' : 'nav-link'}>
                Contact Us
              </Link>
              <Link to="./quote" className="btn btn-primary">
                Get A Quote
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Menu;
