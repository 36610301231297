import React from 'react';
import Hero from '../components/Hero';

const Services = () => {
  return (
    <>
      <Hero
        titleFirstLine={{ text: 'OUR SERVICES', colourClass: 'white' }}
        titleSecondLine={{ text: '', colourClass: 'white' }}
        titleThirdLine={{ text: '', colourClass: 'primary' }}
        titleFourthLine={{ text: '', colourClass: 'white' }}
        subTitleText=""
        paragraphText="Our services range across a variety of web development, design and technology sectors. We work with companies in every industry to achieve high quality products that bring quantifiable results."
      />
    </>
  );
};

export default Services;
