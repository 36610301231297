import React from "react";

export const LayoutContext = React.createContext({
  allLayouts: [
    {
      title: "Welcome",
      slug: "welcome",
      completed: false,
    },
    { title: "Selection", slug: "selection", completed: false },
    { title: "Finance", slug: "finance", completed: false },
    { title: "Part Exchange", slug: "part-exchange", completed: false },
    { title: "Details", slug: "details", completed: false },
    { title: "Summary", slug: "summary", completed: false },
  ],
});
