import React from 'react';
import Hero from '../components/Hero';

const Contact = () => {
  return (
    <>
      <Hero
        titleFirstLine={{ text: 'GET', colourClass: 'primary' }}
        titleSecondLine={{ text: 'IN', colourClass: 'white' }}
        titleThirdLine={{ text: 'TOUCH', colourClass: 'primary' }}
        titleFourthLine={{ text: 'WITH US', colourClass: 'white' }}
        subTitleText="Turn Your Most Extravagant Ideas Into Something Remarkable!"
        paragraphText="We believe in your ideas with as much passion and enthusiasm as you do. We will take your ideas and forge them into a workable solution that doesn’t just function smoothly, but looks amazing too. Our unrivalled work ethic and collaborative approach means your
        project will reflect your business in a truly authentic style."
        image="hero-contact-us"
      />
    </>
  );
};

export default Contact;
